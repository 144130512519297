/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { GmcError } from '@app/core/types';
import { createAction, props } from '@ngrx/store';
import { BillingConfig, BillingTransaction } from '@shared/types/billing';

export const getNebulaBillingHistory = createAction('[Billing] Get Nebula billing history');
export const getNebulaBillingHistoryErr = createAction('[Billing] Get Nebula billing history ERR', props<GmcError>());
export const nebulaBillingHistory = createAction(
  '[Billing] Nebula billing history',
  props<{ data: BillingTransaction[] }>(),
);

export const addBillingLoading = createAction('[Billing] + Loading', props<{ key: string }>());
export const removeBillingLoading = createAction('[Billing] - Loading', props<{ key: string }>());

export const getBillingConfig = createAction('[Billing] Get billing config');
export const getBillingConfigErr = createAction('[Billing] Get billing config err', props<GmcError>());
export const billingConfig = createAction('[Billing] Billing config', props<{ data: BillingConfig }>());
